// src/components/AddTaskForm.js
import React, { useState } from 'react';

const AddTaskForm = ({ onTaskAdded }) => {
  const [title, setTitle] = useState('');
  const [deadline, setDeadline] = useState('');

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDeadlineChange = (e) => {
    setDeadline(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('http://localhost:3001/api/tasks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ title, deadline }),
    })
      .then((response) => response.json())
      .then((data) => {
        onTaskAdded(data);
        setTitle('');
        setDeadline('');
      })
      .catch((error) => console.error('エラー:', error));
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        タスク:
        <input type="text" value={title} onChange={handleTitleChange} />
      </label>
      <label>
        納期:
        <input type="date" value={deadline} onChange={handleDeadlineChange} />
      </label>
      <button type="submit">追加</button>
    </form>
  );
};

export default AddTaskForm;
