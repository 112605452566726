// src/App.js
import React, { useEffect, useState } from 'react';
import AddTaskForm from './components/AddTaskForm';

const App = () => {
  const [tasks, setTasks] = useState([]);

  const getIncompleteTasks = async () => {
    try {
      const response = await fetch('http://localhost:3001/api/tasks');
      const data = await response.json();
      setTasks(data);
    } catch (error) {
      console.error('エラー:', error);
    }
  };

  useEffect(() => {
    getIncompleteTasks();
  }, []);

  const handleTaskAdded = (newTask) => {
    setTasks([...tasks, newTask]);
  };

  const handleTaskCompletion = (taskId) => {
    fetch(`http://localhost:3001/api/tasks/${taskId}/complete`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const updatedTasks = tasks.map((task) =>
          task.id === data.id ? { ...task, completed: true } : task
        );
        setTasks(updatedTasks);
        window.location.reload();

      })
      .catch((error) => console.error('エラー:', error));
  };

  const filteredTasks = tasks.filter((task) => !task.completed);

  return (
    <div>
      <h1>ToDoアプリ</h1>
      <AddTaskForm onTaskAdded={handleTaskAdded} />
      <ul>
        {filteredTasks.map((task) => (
          <li key={task.id}>
            {task.title} (納期: {task.deadline})
            <button onClick={() => handleTaskCompletion(task.id)}>完了</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default App;
